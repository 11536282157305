.paging-info {
  @include font-size(14px, 20px);
  @include make-spacer(mx, auto);
  @include make-spacer(ml, 0, md);
  @include make-spacer(p, 3);
  @include make-spacer(pl, 0, md);

  display: table;
  color: var(--gray-100);
  font-weight: var(--bold);

  @include media-breakpoint-up(md) {
    @include font-size(20px, 14px);
  }

  &__current {
    @include font-size(24px);

    color: var(--green);

    @include media-breakpoint-up(md) {
      @include font-size(35px, 24px);
    }
  }

  &__total {
    display: inline-block;
    vertical-align: top;
  }

  &__slash {
    @include font-size(12px, 18px);
    @include make-spacer(mr, half);
    @include make-spacer(ml, 2);

    display: inline-block;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      @include font-size(14px);
    }
  }
}
