// Spacing
//
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  auto: auto,
  0: 0,
  half: $spacer * 0.25,
  1: $spacer * 0.5,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 5.5,
  12: $spacer * 6,
);
