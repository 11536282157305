.carousel {

  &__item {
    position: relative;
    background-color: var(--gray-lighten);

    @include media-breakpoint-up(md) {
      height: calc(100vh - var(--header-height));
      min-height: rem(672px);
    }
  }

  &__image {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      @include size(100%);

      position: absolute;
      top: 0;
      left: 0;
    }

    &.carousel__image--back { // overwrite slick slider styles
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        opacity: 0.1;
      }
    }

    &--clip {
      @include media-breakpoint-up(md) {
        clip-path: circle(320px at calc(50% - 260px) 50%);
      }
    }
  }

  &__title {
    @include make-spacer(mb, 3);
  }

  &__text {
    @include font-size(14px, 24px);
    @include make-spacer(mb, 3);

    @include media-breakpoint-up(md) {
      @include font-size(16px, 26px);
    }
  }

  &__title,
  &__text {
    @include media-breakpoint-up(sm) {
      padding-right: rem(88px);
      padding-left: rem(88px);
    }

    @include media-breakpoint-up(md) {
      @include make-spacer(px, 0);
    }
  }

  &__button {
    @include size(rem(52px));
    @include flex(center, center);
    @include transition(background-color);

    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
    border-radius: 50%;
    background-color: var(--green);

    @include media-breakpoint-up(md) {
      position: relative;
      opacity: 1;
      background-color: transparent;
    }

    &--prev {
      left: rem(8px);

      @include media-breakpoint-up(md) {
        left: 0;
      }
    }

    &--next {
      right: rem(8px);

      @include media-breakpoint-up(md) {
        right: 0;
      }

      img {
        transform: rotateZ(180deg);
      }
    }

    &.isHovered {
      background-color: var(--green);
    }
  }

  &__wrapper {
    @include make-spacer(ml, auto, md);
    @include make-spacer(pb, 14, md);

    @include media-breakpoint-up(md) {
      @include flex(center, $direction: column);
    }
  }

  &__content {
    @include make-spacer(pt, 12, md);

    @include media-breakpoint-up(md) {
      height: rem(640px);
    }
  }

  &__container {
    @include media-breakpoint-up(md) {
      display: grid;
      position: relative;
      grid-template-columns: 1fr rem(458px);
      grid-gap: rem(60px);
      height: 100%;
    }
  }

  &__trigger {
    display: none;

    @include media-breakpoint-up(md) {
      @include size(rem(640px));

      display: block;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__left {
    @include flex(center, center);
  }

  &__arrows {
    @include media-breakpoint-up(md) {
      @include flex;
      @include absolute-center;

      margin-top: rem(330px);
      margin-left: rem(-175px);
    }
  }
}

.slick-dots {
  @include flex;
  @include absolute-center;

  margin-top: rem(285px);
  margin-left: rem(125px);

  li {
    @include make-spacer(mx, 1);
  }

  button {
    @include size(11px);
    @include transition(border-color);

    border: rem(2px) solid var(--gray-100);
    border-radius: 50%;
    overflow: hidden;
    color: transparent;
  }

  .slick-active {

    button {
      @include size(13px);

      position: relative;
      top: rem(-1px);
      border-color: var(--green);
    }
  }
}
