.contacts {
  @include transition(#{visibility, opacity, transform});
  @include size(100%);
  @include make-spacer(pt, 5);
  @include make-spacer(px, 2);

  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(rem(-24px));
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  background-color: var(--gray-200);

  @include media-breakpoint-up(sm) {
    @include flex(center, $direction: column);

    padding-right: rem(112px);
    padding-left: rem(112px);
  }

  @include media-breakpoint-up(md) {
    @include make-spacer(p, 0);

    position: relative;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    background-color: transparent;
  }

  &.isOpen {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  &__close {
    @include make-spacer(pr, 2);
    @include make-spacer(pb, 2);

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: rem(24px);
      left: rem(24px);
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    &-icon {
      @include font-size(24px);

      fill: var(--green);
    }
  }

  &__title {
    @include font-size(24px);
    @include make-spacer(mb, 3);

    font-weight: var(--bold);

    @include media-breakpoint-up(sm) {
      @include font-size(48px);
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__list {
    @include font-size(18px, 26px);

    color: var(--gray);

    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(24px);
    }

    @include media-breakpoint-up(md) {
      @include font-size(13px);

      grid-template-columns: auto;
      grid-auto-flow: column;
      grid-gap: rem(16px);
    }
  }

  &__item {
    @include make-spacer(mb, 3);
    @include make-spacer(mb, 0, md);
  }

  &__link {
    @include make-spacer(ml, 1, md);

    display: block;
    text-decoration: none;

    &::before {
      @include make-spacer(mx, 1);
      @include size(rem(2px));

      display: inline-block;
      border-radius: 50%;
      background-color: var(--black);
      vertical-align: middle;
      content: '';

      @include media-breakpoint-up(md) {
        content: none;
      }
    }

    @include media-breakpoint-up(md) {
      display: inline;
    }

    &--mail {
      color: var(--green);
    }

    &--tel {
      color: var(--black);
    }
  }

  &__text {
    @include media-breakpoint-up(md) {
      display: inline;
    }
  }
}
