:root {
  --white: #fff;
  --black: #000;
  --gray: #616b78;
  --gray-100: #9ca0a6;
  --gray-200: #f0f2f7;
  --gray-lighten: #f7f7f7;
  --green: #2ea754;
  --green-lighten: #58db81;
  --green-darken: #01532b;
}
