@import 'functions';
@import 'mixins/breakpoints';
@import 'mixins/flexbox';
@import 'mixins/rem-converter';
@import 'mixins/size';
@import 'mixins/spacing';
@import 'mixins/transition';
@import 'mixins/word-break';
@import 'mixins/colors';

@mixin smooth-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-button {
  margin: 0;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: auto;
  overflow: visible;
}

@mixin absolute-center($args...) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) $args;
}

@mixin font-size($size: 16px, $height: $size) {
  line-height: $height / $size;
  font-size: rem($size);
}
