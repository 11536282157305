.languages {

  &__button {
    @include font-size(13px);
    @include make-spacer(p, 1);

    text-transform: uppercase;
    color: var(--gray);

    &:last-child {
      @include make-spacer(pr, 0);
    }

    &.isActive {
      color: var(--green);
    }
  }
}
