.wrapper {
  position: relative;
  padding-bottom: var(--footer-height);

  @include media-breakpoint-up(md) {
    @include make-spacer(pb, 0);

    overflow: hidden;
  }
}
