.preloader {
  @include transition(#{visibility, opacity});
  @include size(100%);
  @include flex(center, center);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--white);

  &__wrapper {
    @include flex;

    position: relative;
  }

  &__item {
    @include size(rem(20px));

    border-radius: 50%;

    &--black {
      position: absolute;
      top: 0;
      left: rem(-16px);
      background-color: var(--black);
      animation: slideToggle 1.2s ease-in-out infinite alternate;
    }

    &--dark {
      background-color: var(--green-darken);
    }

    &--green {
      position: absolute;
      top: 0;
      left: rem(-16px);
      z-index: -1;
      background-color: var(--green);
      animation: slideToggle 1.2s ease-in-out infinite alternate-reverse;
    }
  }

  &.isHidden {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes slideToggle {

  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(32px);
  }
}
