.copyright {
  @include font-size(13px, 24px);
  @include make-spacer(py, 2);

  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--gray-lighten);
  width: 100%;
  text-align: center;
  color: var(--gray);

  @include media-breakpoint-up(md) {
    @include absolute-center;

    margin-top: rem(320px);
    margin-left: rem(135px);
    background-color: transparent;
    width: auto;
  }
}
