.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--white);
  height: var(--header-height);

  &__wrapper {
    @include flex(space-between, center);

    position: relative;
    height: 100%;
  }

  &__button {
    color: var(--green);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__logo {
    @include make-spacer(px, 2);
    @include make-spacer(px, 0, md);

    margin-right: rem(-8px);

    @include media-breakpoint-up(md) {
      position: absolute;
      top: calc(#{rem(16px)} + var(--header-height));
      left: rem(24px);

      img {
        width: rem(142px);
      }

      @media (min-height: 790px) {
        top: calc(#{rem(38px)} + var(--header-height));
      }
    }
  }
}
