* {
  box-sizing: border-box;
}

body {
  @include smooth-font;
  @include make-spacer(mx, auto);
  @include make-spacer(my, 0);

  background-color: var(--gray-lighten);
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: var(--line-height);
  color: var(--black);
  font-family: var(--font-family);
  font-weight: var(--regular);
}

@for $i from 1 through 6 {
  $heading: map-get($headings, h#{$i});

  h#{$i},
  .h#{$i} {
    @include make-spacer(m, 0);

    line-height: var(--headings-line-height);
    color: var(--black);
    font-family: var(--headings-font-family);
    font-size: rem(map-get($heading, 'size'));
    font-weight: var(--bold);
  }
}

h1,
.h1 {
  @include font-size(20px, 26px);

  @include media-breakpoint-up(sm) {
    @include font-size(40px, 48px);
  }
}

p {
  @include make-spacer(m, 0);
}

button {
  @include reset-button;
}

a {
  @include transition(color);

  text-decoration: underline;
  color: var(--black);

  &:hover {
    text-decoration: none;
    color: var(--green-lighten);
  }
}

ul {
  @include reset-list;
}
